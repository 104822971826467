const fetchWithoutCache = (url) => fetch(`${url}?_=${new Date().getTime()}`, {
  method: 'GET',
  cache: 'no-store'
})

export async function measureSpeedAndPing (params, progressCallback) {
  const { imageUrl, textFileUrl, samples } = params
  const results = {
    speeds: [],
    pings: [],
    jitters: []
  }
  const percentBySamples = 100 / (samples + 1)

  for (let i = 1; i <= samples; i++) {
    const speedResult = await measureDownloadSpeed(imageUrl, textFileUrl)
    results.speeds.push(speedResult)

    if (typeof progressCallback === 'function') {
      progressCallback(percentBySamples * i)
    }
  }

  const { ping, jitter } = await measurePing(imageUrl)

  results.pings.push(ping)
  results.jitters.push(jitter)

  if (typeof progressCallback === 'function') {
    progressCallback(100)
  }

  const averageSpeed = results.speeds.reduce((a, b) => a + b, 0) / results.speeds.length
  const averagePing = results.pings.reduce((a, b) => a + b, 0) / results.pings.length
  const averageJitter = results.jitters.reduce((a, b) => a + b, 0) / results.jitters.length

  return {
    speed: averageSpeed.toFixed(0), // kbit/s
    ping: averagePing.toFixed(0), // ms
    jitter: averageJitter.toFixed(0) // ms
  }
}

export async function measureDownloadSpeed (imageUrl) {
  try {
    const startTime = performance.now()
    const imageResponse = await fetchWithoutCache(imageUrl)
    const imageBlob = await imageResponse.blob()
    const endTime = performance.now()
    const durationInSeconds = (endTime - startTime)

    return (imageBlob.size * 8) / durationInSeconds
  } catch (error) {
    console.error('Error during parallel download:', error)
  }
}

export async function measurePing (url) {
  const pingResults = []

  for (let i = 0; i < 5; i++) { // 5 пингов для оценки джиттера
    const startTime = performance.now()
    await fetchWithoutCache(url)
    const endTime = performance.now()

    const pingTime = endTime - startTime
    pingResults.push(pingTime)
  }

  const averagePing = pingResults.reduce((a, b) => a + b, 0) / pingResults.length
  const jitter = Math.max(...pingResults) - Math.min(...pingResults) // Простой расчет джиттера

  return { ping: averagePing, jitter: jitter }
}
