import { THEMES } from '@constants/components'

const defaultState = {
  current: 'default',
  all: THEMES,
  params: [
    {
        name: 'fio',
        visible: true
    },
    {
        name: 'email',
        value: 'support@ecopsy.ru',
        visible: true
    },
    {
        name: 'phone',
        value: '+74956456939',
        visible: true
    }
  ],
  cssStyle: ''
}

export default {
  namespaced: true,

  state: { ...defaultState },

  mutations: {
    SET_THEME (state, themeName) {
      state.current = themeName
    },
    SET_PHONE_NUMBER (state, phoneNumber) {
      state.params.find(obj => obj.name === 'phone').value = phoneNumber
    },
    SET_EMAIL (state, phoneNumber) {
      state.params.find(obj => obj.name === 'email').value = phoneNumber
    },
    SET_PHONE_VISIBLE (state, phoneVisible) {
      state.params.find(obj => obj.name === 'phone').visible = phoneVisible
    },
    SET_EMAIL_VISIBLE (state, emailVisible) {
      state.params.find(obj => obj.name === 'email').visible = emailVisible
    },
    SET_NAME_VISIBLE (state, userNameVisible) {
      state.params.find(obj => obj.name === 'fio').visible = userNameVisible
    }
  },

  actions: {
    setTheme ({ commit, state }, content) {
      if (document.getElementById('custom-styles')) {
        document.getElementById('custom-styles').remove()
      }

      if (content) {
        if (content.params !== undefined && content.cssStyle !== undefined) {
          if (content.params.find(obj => obj.name === 'phone')) {
            let phoneNumber = content.params.find(obj => obj.name === 'phone').value !== undefined
              ? content.params.find(obj => obj.name === 'phone').value
              : '+74956456939'

            if (content.params.find(obj => obj.name === 'phone').visible !== undefined) {
              const phoneVisible = content.params.find(obj => obj.name === 'phone').visible
              commit('SET_PHONE_VISIBLE', phoneVisible)
            } else {
              phoneNumber = '+74956456939'
            }

            commit('SET_PHONE_NUMBER', phoneNumber)
          }

          if (content.params.find(obj => obj.name === 'email')) {
            let email = content.params.find(obj => obj.name === 'email').value !== undefined
              ? content.params.find(obj => obj.name === 'email').value
              : 'support@ecopsy.ru'

            if (content.params.find(obj => obj.name === 'email').visible !== undefined) {
              const emailVisible = content.params.find(obj => obj.name === 'email').visible
              commit('SET_EMAIL_VISIBLE', emailVisible)
            } else {
              email = 'support@ecopsy.ru'
            }

            commit('SET_EMAIL', email)
          }

          if (content.params.find(obj => obj.name === 'fio')) {
            const userNameVisible = content.params.find(obj => obj.name === 'fio').visible !== undefined
              ? content.params.find(obj => obj.name === 'fio').visible
              : true
            commit('SET_NAME_VISIBLE', userNameVisible)
          }
        }

        if (content.cssStyle) {
          const customStyles = document.createElement('style')
          customStyles.id = 'custom-styles'
          customStyles.innerHTML = content.cssStyle
          document.head.append(customStyles)
        }

        if (content.theme) {
          if (!state.all.includes(content.theme)) {
            return new Error(`Темы с именем ${content.theme} не существует!`)
          }
          commit('SET_THEME', content.theme)
        }
      }
    }
  }
}
