<template>
  <div
      :style="styles"
      :class="classes"
      :aria-valuenow="value"
      aria-valuemin="0"
      aria-valuemax="100"
      role="progressbar"
      class="v-progress-linear"
  >
    <div
        :style="{width: `${normalizedValue}%`}"
        class="v-progress-linear__determinate"
    />
  </div>
</template>

<script>
import { Colorable } from '@/mixins'
import { PROGRESS_LINEAR_THEMES } from '@/constants/components'

export default {
  name: 'VProgressLinear',

  mixins: [
    Colorable
  ],

  props: {
    value: {
      type: Number,
      default: 0
    },
    error: {
      type: Boolean
    },
    height: {
      type: String
    },
    theme: {
      type: String,
      validator: (type) => PROGRESS_LINEAR_THEMES.includes(type)
    }
  },

  computed: {
    normalizedValue () {
      if (this.value < 0) {
        return 0
      }

      if (this.value > 100) {
        return 100
      }

      return parseFloat(this.value)
    },

    isFull () {
      return this.value >= 100
    },

    classes () {
      return {
        'v-progress-linear_is_full': this.isFull,
        'v-progress-linear_is_error': this.error,
        [`v-progress-linear_theme_${this.theme}`]: !!this.theme,
        ...this.colorClasses
      }
    },

    styles () {
      return {
        height: this.height
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@styles/variables";
@import "~@styles/tools";

.v-progress-linear {
  $this: &;

  position: relative;
  width: 100%;
  min-width: 7rem;
  height: 1rem;
  @include radius(null);
  overflow: hidden;
  align-items: stretch;
  background-color: #fff;
  // не border потому, что проявлялись артифакты
  box-shadow: inset 0 0 0 1px cl(primary);

  &__determinate {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: cl(primary);
    transition: width .3s;
  }

  &_is {
    &_full {
      box-shadow: inset 0 0 0 1px cl(primary);

      .v-progress-linear__determinate {
        background-color: cl(primary);
      }
    }

    &_error {
      box-shadow: inset 0 0 0 1px cl(danger);

      .v-progress-linear__determinate {
        background-color: cl(danger);
      }
    }
  }

  &_theme {
    &_top {
      top: 0;
      background-color: clha($primary, 0.3);
      border: none;
      border-radius: 0;
      box-shadow: none;
      height: 4px;

      &#{$this}_is {
        &_full {
          background-color: cl(primary);

          .v-progress-linear__determinate {
            background-color: cl(primary);
          }
        }

        &_error {
          background-color: clha($danger, 0.3);

          .v-progress-linear__determinate {
            background-color: cl(danger);
          }
        }
      }
    }
  }

  &_color {
    @each $color in map-keys($theme-colors) {
      &_#{$color} {
        box-shadow: inset 0 0 0 1px cl($color);

        .v-progress-linear__determinate {
          background-color: cl($color);
        }
      }
    }
  }
}
</style>
