/**
 * Detects uBlock Origin, Adblock Plus and AdBlocker Ultimate with JavaScript only.
 * @example: detectAdblock().then((res) => { console.log(res) });
 */
export default function detectAdblock () {
  const url = 'https://www3.doubleclick.net'

  return new Promise(function (resolve) {
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store'
    })
      .then(({ redirected }) => {
        resolve(redirected)
      })
      .catch(() => {
        resolve(true)
      })
  })
}
